import { z } from 'zod';

import { salesAgentSchema } from './sales_agent_schema';

export const teacherAgentSchema = z.object({
  id: z.string(),
  approval: z.boolean(),
  salesAgent: salesAgentSchema,
});

export type TeacherAgent = z.infer<typeof teacherAgentSchema>;
